body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mapboxgl-ctrl-group button {
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25) !important;
    border: none !important;
    background: #fff !important;
}

.mapboxgl-ctrl-group button:last-child {
    border-radius: 15px !important;
}

.mapboxgl-ctrl-group {
    background: transparent !important;
    border-radius: 15px !important;
    box-shadow: none !important;
}

.mapboxgl-ctrl-zoom-in {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.25) !important;
    border-radius: 15px 15px 0 0 !important;
}

.mapboxgl-ctrl-zoom-out {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.25) !important;
    border-radius: 0 0 15px 15px !important;
    margin-bottom: 2px;
}

.mapboxgl-ctrl-geolocate {
    margin-right: 4.5px;
}

.mapboxgl-ctrl-compass:focus {
    border-radius: 15px !important;
}

.mapboxgl-popup {
    max-width: 200px;
}

.mapboxgl-popup-content {
    background-color: transparent;
    background-image: linear-gradient(rgba(255,255,255,1), rgba(255,255,255,0.7));
    border-radius: 15px;
    text-align: center;
    line-height: 23px;
    font-family: 'Lato', sans-serif;
    z-index: 5;
}

.mapboxgl-popup-tip {
    z-index: 6;
    border-color: transparent !important;
}

.mapboxgl-popup-close-button {
    background-color: transparent !important;
    border-radius: 0 15px 0 5px !important;
    height: 25px !important;
    width: 25px !important;
}

.mapboxgl-popup-close-button:hover {
    background-color: rgba(0, 0, 0, 0.4) !important;
}

.popup-info-box {
    border-radius: 8px;
    /* background: rgba(255,255,255,0.5) !important; */
    padding: 5px;
    /* box-shadow: 0px 0px 0px 1px rgb(50, 50, 50); */
    /* box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.5); */
    font-family: 'Lato', 'Open Sans', sans-serif;
}

.popup-info-text {
    margin-top: 0.15em;
    font-size: 0.9em;
    font-weight: normal;
    color: rgb(50, 50, 50);
    display: inline-block;
    line-height: 1.4;
    font-family: 'Rosario', 'Open Sans', 'Source Sans Pro', sans-serif;
}

.popup-info-text br {
    margin-bottom: 0.3em;
}

.view-sightings {
    font-size: 1em;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
    height: 25px;
    width: 100%;
    border-radius: 5px;
    /* background: #fff; */
    filter: none;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    background: #fff;
    /* background-image: linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,1)); */
}

.mapboxgl-popup-content>div {
    border-radius: 15px;
    overflow: hidden;
    padding: 3px;
}
html {
  --show-hide-rotate: 180deg;
  --show-hide-offset-x: -50%;
  --show-hide-offset-y: 0%;
}

.App {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: stretch;
}

.showHide {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 50%;
  margin: auto;
  background: transparent;
  background-image: url(/static/media/rounded-triangle.c4a62e70.svg);
  background-position-y: -1px;
  position: absolute;
  width: 30px;
  height: 30px;
  border: none;
  z-index: 2;
  border: 5px solid transparent;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
}

.showHide:active {
  box-shadow: none !important;
}

.explorer-panel-container {
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
  max-height: 50%;
  overflow: hidden;
  flex-basis: 0;
  background: #fff;
  transition: all 0.1s;
  text-align: center;
}

@media (orientation:landscape) {
  .App {
    flex-flow: row;
  }

  .showHide {
    left: 0;
    top: calc(50% - 20px);
  }

  html {
    --show-hide-rotate: 90deg;
    --show-hide-offset-x: 0%;
    --show-hide-offset-y: -50%;
  }

  .explorer-panel-container{
    max-height: 100%;
  }
}

.explorer-panel-container::before {
  content: "";
  position: absolute;
  inset:0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 5;
  pointer-events: none;
  box-shadow: inset 0 0px 6px 0px rgba(0, 0, 0, 0.5);
}

.map-area-container {
  position: relative;
  flex: 1 1 auto;
  display: block;
}

.map-container {
  width: 100%;
  height: 100%;
  margin: 0;
}

.generic-button {
  position: relative;
  background: #fff;
  margin: 5px;
  border-radius: 5px;
  padding: 3px 5px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25) !important;
  cursor: pointer;
  height: 26px;
  max-height: 26px;
  display: inline-block;
  color: #333;
}
	* {
		scrollbar-width: thin;
		scrollbar-color: rgba(100, 100, 100, 0.3) rgba(255, 255, 255, 0);
	}

	.switch-category-type {
		position: absolute;
		top: 58px;
		right: 110px;
		width: 25px;
		height: 25px;
		box-sizing: border-box;
		border-radius: 25px;
		background: linear-gradient(45deg, rgb(129, 213, 182) 50%, rgb(120, 42, 182) 50%);
		border: solid 3px white;
		transform: rotate(90deg);
		padding: 9px;
		box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
		transition: 0.25s;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		opacity: 1;
		z-index: 2;
	}

	#visibilitySwitch {
		position: absolute;
		visibility: hidden;
		top: 53px;
		right: 45px;
		width: 17px;
		height: 17px;
		border-radius: 22px 0px;
		background-color: #fff;
		transform: rotate(90deg);
		padding: 9px;
		box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
		transition: 0.25s;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		opacity: 1;
		z-index: 2;
	}

	#visibilitySwitch:before {
		position: absolute;
		display: block;
		content: "";
		background: rgba(150, 150, 150, 0.4);
		width: 22px;
		height: 22px;
		border-radius: 100%;
	}

	#visibilitySwitch:after {
		display: block;
		content: "";
		background: #111;
		width: 8px;
		height: 8px;
		border-radius: 100%;
	}

	#visibilitySwitch:hover {
		cursor: pointer;
	}

	#topicSwitch {
		background-color: #FA0087;
	}

	.toSwitch {
		position: absolute;
		bottom: 32px;
		left: 8px;
		width: 29px;
		height: 29px;
		border-radius: 100%;
		z-index: 2;
		border: solid white;
		border-width: 3px;
		box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
	}

	.toSwitch a {
		padding: 15px;
		font-weight: bold;
		text-decoration: none;
	}

	#ownerSwitch {
		background-color: #78527a;
	}

	.filter-group {
		font: 12px/20px 'Lato', Arial, Helvetica, sans-serif;
		position: absolute;
		top: 97px;
		right: 7px;
		z-index: 1;
		border-radius: 14.5px;
		overflow: hidden;
		max-width: min(calc(100vw - 100px), 300px, 60vw);
		min-width: 115px;
		color: #fff;
		display: block;
		transition: 0.25s;
		transform: scaleY(0);
		transform-origin: top;
		box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
		visibility: hidden;
		z-index: 2;
		max-height: calc(100% - 150px);
		overflow-y: scroll;
		text-align: center;
	}

	.filter-group input[type='checkbox']:first-child+label {
		border-radius: 0px 0px 0 0;
	}

	.filter-group label:last-child {
		border-radius: 0 0 5px 5px;
	}

	.filter-group input[type='checkbox'] {
		display: none;
	}

	.filter-group input[type='checkbox']+label {
		display: block;
		cursor: pointer;
		padding: 3px;
		opacity: 0.95;
	}

	.filter-group input[type='checkbox']+label {
		font-weight: normal;
		text-align: center;
		color: rgba(255, 255, 255, 0.7);
	}

	.filter-group input[type='checkbox']+label:hover, .filter-group input[type='checkbox']:checked+label {
		/*        background-color: #4ea0da; */
		box-shadow: inset 0px -4px 0px -3px rgba(255, 255, 255, 0.9);
	}

	.filter-group input[type='checkbox']:checked+label {
		color: rgba(255, 255, 255);
	}

	.filter-group input[type='checkbox']:checked+label .filterName {
		color: rgba(255, 255, 255);
	}

	#filterHeader {
		height: 29px;
		width: 115px;
		background-color: #fff;
		color: black;
		text-align: center;
		border-radius: 14.5px;
		cursor: pointer;
		opacity: 0.95;
		display: flex;
		justify-content: center;
		align-items: center;
		float: right;
		display: none;
	}

	#filterBody {
		width: 100%;
		overflow-y: scroll;
		max-height: calc(100vh - 160px);
		z-index: 2;
		position: relative;
	}

	@media only screen and (max-width: 340px) {
		#filterBody {
			border-radius: 0px;
		}

		/* CSS that should be displayed if width is equal to or less than 800px goes here */
	}

	#filterHeader:hover {
		color: grey;
	}

	.box {
		width: 20%;
		margin: 0 auto;
		background: rgba(255, 255, 255, 0.2);
		padding: 35px;
		border: 2px solid #fff;
		border-radius: 20px/50px;
		background-clip: padding-box;
		text-align: center;
		z-index: 3;
	}

	.overlay {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgba(0, 0, 0, 0.7);
		visibility: hidden;
		opacity: 0;
		justify-content: center;
		align-items: center;
		display: flex;
	}

	.overlay:target {
		visibility: visible;
		opacity: 1;
	}

	.popup {
		z-index: 3;
		margin: 70px auto;
		padding: 15px;
		background: rgba(233, 241, 241, 1);
		box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
		border-radius: 3px;
		width: auto;
		display: inline-block;
		max-width: 90vw;
		position: relative;
		overflow: auto;
		font-size: 1.3em;
	}

	.popup h2 {
		margin-top: 0;
		font-size: 1em;
		color: #333;
		font-family: 'Lato', 'Open Sans', Tahoma, Arial, sans-serif;
	}

	.popup .close {
		position: absolute;
		top: 5px;
		right: 10px;
		transition: all 200ms;
		font-size: 30px;
		font-weight: bold;
		text-decoration: none;
		color: #333;
	}

	.popup .close:hover {
		color: red;
	}

	.popup .content {
		max-height: 70vh;
		width: auto;
		overflow: auto;
		scrollbar-color: #73878c #ededed;
		font-family: 'Lato', 'Open Sans', Tahoma, Arial, sans-serif;
	}


	#navNBNLink {
		display: flex;
		position: absolute;
		left: 50%;
		bottom: 4px;
		transform: translateX(-50%);
		z-index: 2;
		background-color: rgba(255, 255, 255, 0.95);
		padding: 2px 5px 2px 5px;
		border-radius: 14.5px;
		font-family: 'Lato', 'Open Sans', Tahoma, Arial, sans-serif;
		height: 18px;
		justify-content: center;
		align-items: center;
		border-radius: 5px 5px 0 0;
		border-bottom: none;
		bottom: 0;
		box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
	}

	.filtHeading {
		text-align: center;
	}

	.abox {
		background-color: #777;
		color: white;
		font-weight: bold;
		padding: 4px 7px 4px 7px;
		text-decoration: none;
		border-radius: 14.5px;
		align-items: center;
	}

	.abox:hover {
		filter: brightness(120%);
	}

	.othera {
		font-weight: bold;
		text-decoration: none;
	}

	.othera:hover {
		filter: drop-shadow(0 2px 0px rgba(255, 255, 255, 1));
		cursor: pointer;
	}

	.iucnp {
		color: #fff;
		font-size: 0.75em;
		padding: 2px;
		text-decoration: none;
		border-radius: 5px;
		vertical-align: middle;
	}

	.dot {
		height: 1em;
		width: 1em;
		border-radius: 1em;
		display: inline-block;
		position: relative;
		float: right;
		opacity: 70%;
	}

	mark {
		background: linear-gradient(-100deg, hsla(57, 0%, 100%, 0.3), hsla(57, 100%, 100%, 0.95));
		border-radius: 1em 3px;
		padding: 1px 3px 1px 3px;
	}

	#pub-container {
		text-align: center;
		width: 100%;
		display: block;
	}

	#loadPubsLink {
		background-color: rgba(0, 0, 0, 0);
		text-shadow: 1px 1px rgba(223, 142, 3, 0.8);
	}

	#loadPubsLink:hover {
		cursor: pointer;
		text-shadow: 2px 2px rgba(255, 199, 102, 0.8);
	}

	/* plete  */

	#speciesLocatorContainer {
		display: none;
		position: absolute;
		top: 10px;
		left: 50px;
		width: calc(100vw - 50px - 45px - 10px);
	}

	.autocomplete-form {
		display: flex;
	}

	.autocomplete {
		font-weight: 600;
		position: relative;
		/*          top: 10px;
          left: 50px;
*/
		z-index: 1;
		border-radius: 14.5px;
		/*          width: 160px;
*/
		color: #fff;
	}

	input {
		position: relative;
		display: flex;
		border: 0px solid transparent;
		height: 19px;
		padding: 5px;
		font-size: 15px;
		border-radius: 14.5px;
		z-index: 1;
		box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
		justify-content: center;
		pointer-events: auto;
	}

	input[type=text] {
		font: 11px 'Lato', 'Open Sans', Arial, Helvetica, sans-serif;
		background-color: rgba(233, 241, 241, 1);
		width: min(calc(100vw - 190px), 200px);
		z-index: 1;
		margin-bottom: 3px;
		color: #222;
	}

	.alButton {
		font: 11px 'Open Sans', Arial, Helvetica, sans-serif;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 14.5px;
		padding: 5px;
		height: 19px;
		width: 19px;
		font-weight: bold;
		text-align: center;
		box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
		cursor: pointer;
		pointer-events: auto;
	}

	.al-submit {
		background-color: rgba(190, 218, 218, 0.95);
		color: #333;
		margin-left: 3px;
	}

	.al-close {
		margin-left: 3px;
		display: flex;
		background-color: rgba(200, 80, 80, 0.95);
		color: #333;
		top: 0px;
		left: 196px;
	}

	#speciesLocatorOptions {
		display: inline-block;
		position: absolute;
		top: 10px;
		left: 50px;
		padding: 1px;
		z-index: 1;
		width: calc(100vw - 50px - 62px);
		pointer-events: none;
	}

	.locatorOpenButton {
		display: inline-block;
		z-index: 1;
		background-color: rgba(190, 218, 218, 0.85);
		color: #333;
		padding: 6px;
		width: 31px;
		height: 31px;
		vertical-align: top;
		margin-bottom: 3px;
		pointer-events: all;
		border-radius: 21px;
		background: rgb(233, 241, 241);
	}

	.searchExitButton {
		display: inline-flex;
		z-index: 1;
		background-color: rgba(200, 80, 80, 1);
		color: #333;
		padding: 0px;
		width: 43px;
		height: 43px;
		vertical-align: center;
		margin-bottom: 3px;
		pointer-events: all;
		border-radius: 21px;
		font-size: 21.5px;
		justify-content: center;
		align-items: center;
	}

	.autocomplete-items {
		font: 12px 'Lato', 'Open Sans', Arial, Helvetica, sans-serif;
		position: absolute;
		box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
		border-radius: 14.5px;
		border-bottom: none;
		border-top: none;
		z-index: 2;
		/*position the autocomplete items to be the same width as the container:*/
		top: 107%;
		width: 100%;
		left: 0;
		right: 0;
		color: #555;
		overflow-y: scroll;
		max-height: calc(100vh - 50px);
	}

	.autocomplete-items div {
		z-index: 2;
		padding: 5px;
		cursor: pointer;
		background-color: rgba(233, 241, 241, 0.95);
		border-bottom: 1px solid #fff;
	}

	.autocomplete-items div:last-child {
		border-radius: 0 0 5px 5px;
		border-bottom: none;
	}

	.autocomplete-items div:first-child {
		border-radius: 5px 5px 0 0;
	}

	.autocomplete-items div:hover {
		/*when hovering an item:*/
		background-color: rgba(217, 232, 232, 0.95);
		z-index: 3;
	}

	.autocomplete-active {
		z-index: 3;
		/*when navigating through the items using the arrow keys:*/
		background-color: rgba(93, 162, 162, 0.95) !important;
		color: #ffffff;
	}

	#tableToBuild {
		z-index: 0;
		color: #555;
		overflow-y: scroll;
		position: absolute;
		left: 0px;
		top: 47px;
		border-radius: 0 5px 5px 5px;
		max-height: min(30vmax, 90vh);
		box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
		background-color: rgba(233, 241, 241, 0.95);
	}

	.imgIcon {
		max-width: 100%;
		max-height: 100%;
		display: block;
	}

	#speciesShowing {
		display: none;
		position: absolute;
		font: 12px 'Lato', 'Open Sans', Arial, Helvetica, sans-serif;
		color: #555;
		z-index: 1;
		top: 32px;
		font-weight: bold;
		height: 15px;
		max-width: 192px;
		text-align: bottom;
		background-color: rgba(233, 241, 241, 0.95);
		border-radius: 3px 3px 0 0;
		padding-left: 3px;
		padding-right: 3px;
		box-shadow: 0px -1px 1px 0px rgba(0, 0, 0, 0.15);
	}

	.alCardWhole {
		font: 12px 'Lato', 'Open Sans', Arial, Helvetica, sans-serif;
	}

	.alCardHead {
		width: 100%;
	}

	.alCardHead a {
		padding-left: 5px;
		width: 100%;
		color: #5da2a2;
	}

	.alCardHead a:hover {
		filter: none;
		color: #555;
	}

	.alCardBody {
		width: 100%;
		display: inline-block;
		padding: 5px;
	}

	.alCardBody span:first-child {
		padding-left: 10px;
		position: relative;
		display: inline-block;
		width: 50px;
	}

	.alCardBody {
		padding-bottom: 5px;
	}

	::-webkit-scrollbar {
		width: 0px;
		background: transparent;
		/* make scrollbar transparent */
	}

	.top100Marker {
		display: block;
		position: relative;
		border-radius: 0px;
		cursor: pointer;
		padding: 0;
		border-top: solid 2px #254141;
		border-radius: 2px;
		background-color: rgba(255, 255, 255, 0.3);
		width: 20px;
		height: 20px;
		text-align: center;
		color: #254141;
		font-family: 'Open Sans', sans-serif;
		font-weight: bold;
	}

	.top100Marker:before {
		content: "";
		position: absolute;
		bottom: -5px;
		left: 5px;
		width: 0;
		height: 0;
		border-left: 5px solid transparent;
		border-right: 5px solid transparent;
		border-bottom: 5px solid #254141;
	}

	.inatMarker {
		display: block;
		border: none;
		border-radius: 0px;
		cursor: pointer;
		padding: 0;
		border-color: #fff;
		border-style: solid;
		border-width: 3px;
		background-color: rgba(0, 80, 0, 0.2);
		width: 15px;
		height: 19px;
		text-align: center;
		color: #254141;
		font-family: 'Rosario', sans-serif;
		font-weight: bold;
		box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.35);
	}

	.inatMarker:before {
		content: "";
		position: absolute;
		height: 6px;
		width: 18px;
		border-radius: 0px;
		background: #fff;
		top: 15px;
		left: -1px;
	}

	.inatImage {
		object-fit: cover;
		width: 150px;
		height: 150px;
		border-radius: 8px;
		cursor: pointer;
	}

	.sightingsDiv {
		width: calc(100% - 10px);
		background-color: #fff;
		text-align: center;
		cursor: pointer;
		margin-top: 7px;
		padding: 5px;
		border-radius: 5px;
	}

	.sightingsDiv:hover {
		background-color: rgb(250, 250, 250);
	}

	.sightingFilter {
		margin-top: 7px;
	}

	.sightings-popup-close-button {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		right: 0;
		top: 0;
		border: 0;
		border-radius: 0 3px 0 3px;
		cursor: pointer;
		background-color: transparent;
		font-size: 1em;
	}

	.sightings-popup-close-button:hover {
		background-color: rgba(200, 80, 80, 0.95);
	}

	#mainSearchIcon {
		position: absolute;
		top: 10px;
		left: 50px;
		width: 45px;
		height: 45px;
		border-radius: 45px;
		background: #fff;
		box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
		display: flex;
		cursor: pointer;
		transition: 0.25s;
	}

	#speciesLocatorOptions {
		display: none;
	}

	#magnifying-glass {
		margin: auto;
		transform: translate(-2px, -2px) rotate3d(0, 0, 0, 0deg);
		font-size: 30px;
		/* This controls the size. */
		display: inline-block;
		width: 0.4em;
		height: 0.4em;
		border: 0.1em solid #333;
		position: relative;
		border-radius: 0.35em;
		transition: 0.25s;
	}

	#magnifying-glass::before {
		content: "";
		display: inline-block;
		position: absolute;
		right: -0.25em;
		bottom: -0.11em;
		border-width: 0;
		border-radius: 5px;
		background: #333;
		width: 0.3em;
		height: 0.12em;
		transform: rotate(45deg);
	}

	#search-options {
		position: absolute;
		visibility: hidden;
		top: 57px;
		left: 50px;
		background: #fff;
		height: 0px;
		min-width: 200px;
		max-width: calc(100vw - 50px - 162px);
		font: 11px 'Open Sans', Arial, Helvetica, sans-serif;
		border-radius: 15px;
		box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
		justify-content: center;
		overflow: hidden;
		transition: 0.25s;
	}

	.search-option {
		font-weight: 600;
		height: 29px;
		padding-left: 48px;
		width: calc(100% - 48px);
		color: #222;
		text-align: center;
		cursor: pointer;
		opacity: 0.95;
		display: flex;
		justify-content: left;
		align-items: center;
		cursor: pointer;
		box-shadow: inset 0px -4px 0px -2px #fff;
	}

	.search-option:hover {
		box-shadow: inset 0 0px 4px 0px rgba(0, 0, 0, 0.2);
	}

	#filter-icon {
		position: absolute;
		display: block;
		top: 12px;
		width: 0;
		height: 0;
		border-left: 12px solid transparent;
		border-right: 12px solid transparent;
		border-radius: 6px;
		border-top: 15px solid #333;
		transition: 0.25s;
	}

	#filter-icon:before {
		content: "";
		position: absolute;
		top: -4px;
		width: 4px;
		height: 13px;
		transform: translate(-2px);
		background: #333;
		border-radius: 0 0 10px 0;
	}

	#mainFilterIcon {
		position: absolute;
		display: flex;
		top: 46px;
		right: 6px;
		width: 45px;
		height: 45px;
		border-radius: 45px;
		background: #fff;
		box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
		display: flex;
		cursor: pointer;
		transition: 0.25s;
		align-items: center;
		justify-content: center;
	}

	#filter-icon-drip {
		content: "";
		position: relative;
		display: inline-block;
		transform: rotate(-45deg);
		top: 5px;
		background: #bbe;
		width: 4px;
		height: 4px;
		border-top-left-radius: 50%;
		border-bottom-left-radius: 50%;
		border-bottom-right-radius: 50%;
		transition: 0.25s;
	}

	select {
		font-size: 1em;
		font-family: 'Lato', 'Open Sans';
		background-color: #fff;
		border: none;
		border-radius: 2px;
		display: inline-block;
		padding: 0em 3em 0em 0.3em;
		height: 2em;
		margin: 0;
		box-shadow: inset 0 1px 2px 0 #ccc;
		pointer-events: auto;
		color: #333;
	}

	#remove-inat-markers {
		font-size: 16px;
		position: absolute;
		display: flex;
		left: 14px;
		top: 103px;
		background: rgba(255, 120, 150);
		justify-content: center;
		align-items: center;
		color: #fff;
		visibility: hidden;
	}

	#remove-inat-markers p {
		transform: translate(0px, -5px);
		font-size: 18px;
		font-weight: 400;
	}

	#single-place-search-container {
		display: flex;
		flex-direction: column;
		position: absolute;
		top: 10px;
		left: 50px;
		padding: 1px;
		z-index: 1;
		width: calc(100vw - 50px - 62px);
		visibility: hidden;
		pointer-events: none;
	}

	.search-bar-container {
		display: inline-flex;
		width: 100%;
	}

	.place-input {
		background-color: rgb(255, 255, 255) !important;
	}

	.place-search-results {
		overflow: auto;
		max-height: calc(100vh - 155px);
		width: auto;
	}

	#single-place-search-results {}

	.place-search-result {
		margin-top: 3px;
		border-radius: 3px;
		padding: 5px;
		margin-bottom: 2px;
		width: min(calc(100vw - 185px), 200px);
		cursor: pointer;
		box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
		background: #fff;
		pointer-events: auto;
	}

	.place-search-result:hover {
		box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
	}

	.place-search-result-head {
		font-family: 'Lato', 'Open Sans', sans-serif;
		font-weight: 600;
		color: #222;
	}

	.place-search-result-body {
		font-family: 'Rosario', 'Open Sans', sans-serif;
		font-weight: 400;
		font-size: 0.9em;
		color: #555;
	}

	#mitm-container {
		visibility: hidden;
		display: flex;
		flex-direction: column;
		position: absolute;
		top: 10px;
		left: 50px;
		padding: 1px;
		z-index: 1;
		width: calc(100vw - 50px - 62px);
		pointer-events: none;
	}

	#mitm-locations-container {
		width: 100%;
		display: flex;
	}

	.mitm-location {
		font-family: 'Lato', 'Open Sans', sans-serif;
		display: flex;
		top: 46px;
		right: 6px;
		width: 45px;
		height: 45px;
		border-radius: 45px;
		background: #fff;
		box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
		display: flex;
		cursor: normal;
		transition: 0.25s;
		align-items: center;
		justify-content: center;
		margin-right: 5px;
		margin-bottom: 5px;
		word-break: break-all;
		overflow: hidden;
		font-size: 12px;
		text-align: center;
		font-weight: 600;
		color: #333;
		pointer-events: auto;
	}

	#mitm-add-location {
		background-color: rgba(255, 255, 255, 0.8);
		cursor: pointer;
	}

	#mitm-add-location:hover {
		background-color: rgba(255, 255, 255, 1);
	}

	#mitm-add-location:before {
		content: "";
		position: absolute;
		background-color: rgb(100, 100, 100);
		width: 3px;
		height: 15px;
		border-radius: 3px;
	}

	#mitm-add-location:after {
		content: "";
		position: absolute;
		background-color: rgb(100, 100, 100);
		width: 15px;
		height: 3px;
		border-radius: 3px;
	}

	#mitm-exit {
		background-color: rgba(200, 80, 80, 0.8);
		cursor: pointer;
	}

	#mitm-exit:hover {
		background-color: rgba(200, 80, 80, 1);
	}

	#mitm-exit:after {
		content: "";
		width: 0;
		height: 0;
		border-top: 10px solid transparent;
		border-bottom: 10px solid transparent;
		border-right: 10px solid rgba(40, 20, 20, 1);
		border-radius: 10px;
	}

	#mitm-search-bar {
		visibility: hidden;
		display: flex;
		flex-wrap: wrap;
	}

	#selectDriveTimeContainer {
		flex-basis: 100%;
		margin-bottom: 5px;
	}

	#selectDriveTime {
		box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
		border-radius: 14.5px;
		width: 120px;
		margin-top: 4px;
	}

	.mitm-location-exit {
		position: absolute;
		display: flex;
		width: 22px;
		height: 22px;
		border-radius: 20px;
		background: rgba(200, 80, 80, 0.9);
		transform: translate(15px, -15px);
		color: #fff;
		font-size: 17px;
		align-items: top;
		justify-content: center;
		cursor: pointer;
	}

	.bird {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		transform: translate(4px, 5px);
	}

	.bird-beak {
		position: absolute;
		border: solid 4px transparent;
		border-left: solid 8px #f5bf42;
		height: 1px;
		border-radius: 2px;
		transform: rotate(18deg) translate(16px, 0px);
	}

	.bird-main {
		background: #333;
		width: 22px;
		height: 22px;
		border-radius: 30px;
	}

	.bird-eye {
		position: absolute;
		width: 4px;
		height: 4px;
		border-radius: 4px;
		background: #ddd;
		transform: translate(3px, -2px);
	}

	.butterfly-icon {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		transform: translate(6px, 7px);
	}

	.bwing {
		position: absolute;
		background: #333;
		width: 10px;
		height: 13px;
	}

	.bwing-a {
		border-radius: 0 8px;
		transform: translate(-12px, -11px);
	}

	.bwing-b {
		border-radius: 8px 0;
		transform: translate(-12px, 0px);
	}

	.bwing-c {
		border-radius: 8px 0;
		transform: translate(0px, -11px);
	}

	.bwing-d {
		border-radius: 0 8px;
		transform: translate(0px, 0px);
	}

	.bfly-antenna-a {
		position: absolute;
		width: 1px;
		height: 8px;
		background: #619F60;
		top: -5px;
		left: 10px;
		transform: rotate(15deg);
	}

	.bfly-antenna-b {
		position: absolute;
		width: 1px;
		height: 8px;
		background: #619F60;
		top: -5px;
		left: 8px;
		transform: rotate(-15deg);
	}

	.bfly-body {
		position: absolute;
		width: 4px;
		height: 20px;
		background: #619F60;
		border-radius: 50%;
		transform: translate(-6px, -5px);
	}

	.liz-icon {
		width: 100%;
		height: 100%;
		border-radius: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		-webkit-clip-path: circle(20px at center);
		        clip-path: circle(20px at center);
	}

	.liz-head {
		position: absolute;
		border: solid 4px transparent;
		border-bottom: solid 20px #333;
		width: 10px;
		height: 20px;
		border-radius: 0px;
		transform: translate(0, -2px);
	}

	.liz-tongue-main {
		position: absolute;
		background: #c28496;
		width: 4px;
		height: 8px;
		border-radius: 0px;
		transform: translate(0, -4px);
	}

	.liz-fork-a {
		position: absolute;
		border: solid 4px transparent;
		border-left: 0;
		border-bottom: solid 8px #c28496;
		transform: translate(0, -13px)
	}

	.liz-fork-b {
		position: absolute;
		border: solid 4px transparent;
		border-right: 0;
		border-bottom: solid 8px #c28496;
		transform: translate(0, -13px)
	}

	.liz-eye-a {
		position: absolute;
		width: 3px;
		height: 4px;
		border-radius: 4px;
		background: rgb(233, 241, 241);
		transform: translate(5px, 19px);
	}

	.liz-eye-b {
		position: absolute;
		width: 3px;
		height: 4px;
		border-radius: 4px;
		background: rgb(233, 241, 241);
		transform: translate(-5px, 19px);
	}

	.mouse-icon {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.mouse-face {
		position: absolute;
		width: 20px;
		height: 20px;
		border-radius: 100%;
		background: #333;
		transform: translate(0, 3px);
	}

	.mouse-ear-l {
		position: absolute;
		width: 6px;
		height: 6px;
		border-radius: 20px;
		border: solid 3px #333;
		transform: translate(-6px, -6px);
	}

	.mouse-ear-r {
		position: absolute;
		width: 6px;
		height: 6px;
		border-radius: 20px;
		border: solid 3px #333;
		transform: translate(6px, -6px);
	}

	.mouse-nose {
		position: absolute;
		width: 5px;
		height: 4px;
		border-radius: 4px;
		background: #f4a5a5;
		transform: translate(0, 5px);
	}

	.mouse-eye-l {
		position: absolute;
		width: 2px;
		height: 3px;
		border-radius: 4px;
		background: rgb(233, 241, 241);
		transform: translate(-4px, -1px);
	}

	.mouse-eye-r {
		position: absolute;
		width: 2px;
		height: 3px;
		border-radius: 4px;
		background: rgb(233, 241, 241);
		transform: translate(4px, -1px);
	}

	.alButton:hover {
		filter: brightness(110%) saturate(1.1);
	}

	#open-tty-button {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 40px;
		background: rgb(66, 138, 102);
		border-radius: 5px;
		color: rgb(233, 241, 241);
		font-weight: 600;
		margin: 5px 0 5px 0;
		box-shadow: -4px 5px 0 0px #bbddcd;
		cursor: pointer;
		text-decoration: none;
	}

	.nodec {
		text-decoration: none;
	}

	#open-tty-button:hover {
		transform: translate(-1px, 1px);
		box-shadow: -3px 4px 0 0px #bbddcd;
	}

	.popup-tty {
		position: absolute;
		z-index: 4;
		padding: 15px;
		background: rgba(255, 255, 255, 1);
		box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
		border-radius: 3px;
		max-width: 100%;
		max-height: 90%;
		display: inline-block;
		max-width: 90vw;
		position: relative;
		overflow: auto;
		font-size: 1.3em;
	}

	.overlay-tty {
		z-index: 4;
		background-color: rgba(255, 255, 255, 0.8);
	}

	.tty-subheader {
		color: #555;
		font-weight: 400;
		font-style: italic;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	#popup2-close {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 4;
		width: 40px;
	}

	#tty-popup-main-container {
		width: 100%;
		display: flex;
		justify-content: center;
		flex-direction: column;
	}

	#species-quiz-div {
		margin-top: 10px;
		padding-top: 5px;
		height: 40px;
		border-top: solid 1px #abc;
	}

	#species-quiz-div img {
		width: 40px;
		height: 40px;
	}

	#species-quiz-div a {
		text-decoration: none;
		display: flex;
		align-items: center;
		justify-content: left;
	}

	#species-quiz-div span b {
		font-size: 1.1em;
	}

	#species-quiz-div span {
		width: calc(100% - 40px);
		text-decoration: none;
		color: #333;
		font-family: "Lato", "Source Sans Pro", sans-serif;
		font-size: 0.9em;
	}

	#loading-progress {
		position: absolute;
		bottom: 21px;
		width: 0px;
		height: 5px;
		border-radius: 5px;
		background: #5da2a2;
		display: flex;
		visibility: visible;
		transition: 1.5s;
	}

	button {
		border: none;
	}

	button:active, #visibilitySwitch:active,
	.alButton:active, .explore-sightings-container:active {
		box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.25) !important;
	}
.butterfly-container {
	margin-left: max(calc(50% - 500px), 0px);
	width: min(90vw, 750px);
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.canvas-container>canvas {
	position: relative;
}

.canvas-container {
	position: absolute;
	bottom: 0;
	margin: auto;
	width: 100%;
	max-width: 100%;
	display: flex;
	overflow: hidden;
	border-radius: 0, 0, 8px 8px;
	pointer-events: none;
	filter: drop-shadow(0px -1px 0px #fff);
}

.month-on-canvas {
	position: absolute;
	display: inline-flex;
	width: calc(100% / 12);
	padding-top: 1px;
	height: 100%;
	line-height: 1em;
	justify-content: center;
	text-align: center;
	font-family: 'Source Sans Pro';
	font-size: 0.6em;
	color: rgba(0, 0, 0, 0);
}

.month-on-canvas:hover {
	color: rgba(0, 0, 0, 1);
	background: hsla(140, 40%, 40%, 0.05);
}

.butterfly-info {
	max-width: 250px;
	padding: 2px 5px;
	margin: 2px;
	font-weight: 600;
	color: #333;
	background: rgb(233, 241, 241);
	cursor: pointer;
	border-radius: 5px;
	word-wrap: normal;
}

@media (max-width: 600px) {
	.butterfly-info {
		width: 80px;
		font-size: 0.9em !important;
	}
}

@media (max-width: 750px) and (min-width: 600px) {
	.butterfly-info {
		width: calc(100vw - 500px);
	}
}

#header {
	width: 100%;
	font-size: 2em;
	font-weight: 700;
	text-align: center;
	color: #333;
}

#but-details-container {
	position: relative;
	padding-top: 5px;
	padding-bottom: 25px;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	animation: expand .2s ease-in-out;
	overflow: hidden;
}

#but-details-container>* {
	margin: 5px;
}

.species-main-image {
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: 5px;
	background-image: linear-gradient(to bottom, #bedada 0%, #e0ebeb 51%);
	background-size: cover;
	color: #fff;
	background-position: center;
}

.bin-fam-names {
	position: absolute;
	top: 20%;
	left: 0;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	display: block;
}

.bin-fam-names>div {
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
}

.bin-fam-names>div>b {
	font-weight: 600;
	font-size: calc(1em - 2px);
	padding: 0px 4px 0px 4px;
	color: #000;
	background: rgba(255, 255, 255, 0.4);
}

.bin-fam-names>div>i {
	font-family: 'Lora';
	font-size: 1em;
	font-weight: 400;
	padding: 0px 4px 2px 4px;
	background: rgba(0, 0, 0, 0.5);
}

.butterfly-further-info {
	width: 100%;
	height: 100%;
	border-radius: 5px;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	overflow: hidden;
}


@keyframes expand {
	from {
		height: 0;
	}
}

.image-credit {
	font-size: 0.9em;
	position: absolute;
	bottom: 30px;
	right: 0;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	background: rgba(0, 0, 0, 0.3);
	padding: 0px 4px 0px 4px;
	text-align: right;
}

.image-credit .jsa {
	color: #cfc;
	font-style: italic;
	cursor: pointer;
}

.species-info-links {
	position: absolute;
	top: 0;
	width: calc(100% - 5px);
	height: 15%;
	display: flex;
	flex-direction: row;
	padding: 5px;
	justify-content: center;
	align-items: center;
	z-index: 5;
}

.bf-link {
	margin: 0 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 45px;
	height: 45px;
	background-position: center;
	background-size: cover;
	cursor: pointer;
	transition: 0.2s;
	filter: drop-shadow(2px 2px 0px rgba(255, 255, 255, 1));
}

.bf-link:hover {
	filter: drop-shadow(3px 3px 0px rgba(0, 0, 0, 0.4));
}

.bf-link-ecosia {
	background-image: url(/static/media/ecosia_mglass.18f73392.png);
}

.bf-link-wiki {
	background-image: url(/static/media/wikipedia.8473e8bb.png);
}

.bf-link-nbn {
	background-image: url(/static/media/nbn_atlas.6a7cd8fa.png);
}
#outwhere-logo {
	position: relative;
	width: 100px;
	height: 100px;
}

.ow-circle {
	position: absolute;
	border-radius: 50%;
	transform: translate(-50%, -50%);
	transition: 20ms ease-in-out;
	animation: load1 1.25s infinite alternate;
}

#oc1 {
	top: 13%;
	left: 44.5%;
	width: 24%;
	height: 24%;
	background: rgb(126, 196, 149);
}

#oc2 {
	top: 24%;
	left: 42%;
	width: 10%;
	height: 10%;
	background: rgb(72, 133, 80);
}

#oc3 {
	top: 25%;
	left: 56.5%;
	width: 23%;
	height: 23%;
	background: rgb(67, 159, 71);
}

#oc4 {
	top: 36%;
	left: 44.5%;
	width: 28%;
	height: 28%;
	background: rgb(72, 164, 102);
}

#oc5 {
	top: 48.2%;
	left: 56.2%;
	width: 23.5%;
	height: 23.5%;
	background: rgb(60, 149, 40);
}

#oc6 {
	top: 48.5%;
	left: 32.5%;
	width: 19%;
	height: 19%;
	background: rgb(61, 189, 75);
}

#oc7 {
	top: 60.5%;
	left: 32.5%;
	width: 25%;
	height: 25%;
	background: rgb(49, 165, 72);
}

#oc8 {
	top: 60.5%;
	left: 20.5%;
	width: 24%;
	height: 24%;
	background: rgb(66, 169, 70);
}

#oc9 {
	top: 60%;
	left: 52.5%;
	width: 8.5%;
	height: 8.5%;
	background: rgb(94, 201, 109);
}

#oc10 {
	top: 60.5%;
	left: 68.5%;
	width: 24%;
	height: 24%;
	background: rgb(87, 188, 91);
}

#oc11 {
	top: 72.4%;
	left: 56.2%;
	width: 28%;
	height: 28%;
	background: rgb(65, 170, 85);
}

#oc12 {
	top: 72.2%;
	left: 68.3%;
	width: 15%;
	height: 15%;
	background: rgb(86, 194, 105);
}

#oc13 {
	top: 84.2%;
	left: 44.3%;
	width: 17%;
	height: 17%;
	background: rgb(52, 159, 118);
}

#oc14 {
	top: 84.2%;
	left: 56.3%;
	width: 24%;
	height: 24%;
	background: rgb(80, 188, 99);
}

#oc15 {
	top: 84.2%;
	left: 68.3%;
	width: 16.2%;
	height: 16.2%;
	background: rgb(49, 153, 79);
}

#oc16 {
	top: 84.3%;
	left: 80.4%;
	width: 28.3%;
	height: 28.3%;
	background: rgb(58, 188, 158);
}

@keyframes load1 {
	0% {
		height: 0px;
		width: 0px;
		left: 50%;
		top: 50%;
		transform: translate(0, 100%);
	}
}
.sightings-explorer-container>details>summary {
    font-weight: 200;
    padding: 0;
    transition: all 0.1s;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    box-shadow: inset 0 -2px 2px -2px rgba(0, 0, 0, 0.2);
    color: hsl(158, 30%, 35%);
    background: hsla(158, 40%, 80%, 0.2);
    overflow: hidden;
}

.sightings-explorer-container>details>summary:hover {
    background: hsla(158, 40%, 80%, 0.3);
}


summary::-webkit-details-marker {
    display: none;
}

summary {
    cursor: pointer;
    list-style: none
}

summary::-webkit-details-marker {
    display: none;
}

.scrubbable-number {
    font-size: 25px;
    font-weight: 600;
    height: 30px;
    filter: drop-shadow(1px 1px 0px #333);
    -webkit-user-select: none;
            user-select: none;
}

.sightings-explorer-container {
    position: absolute;
    margin: 0;
    width: 100%;
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
}

.dropdown {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
}

.explorer-input>label {
    -webkit-user-select: none;
            user-select: none;
}

.sightings-explorer-container>details {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    transition: all 0.2s;
}

.details-container {
    width: calc(100% - 16px);
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 8px;
    grid-gap: 8px;
    gap: 8px;
}

.sightings-explorer-container>details[open]>summary {
    background: hsla(15, 73%, 80%, 0.2);
    font-size: 1em;
    height: 20px;
}

.sightings-table-container {
    position: relative;
    width: calc(100% - 10px);
    padding: 5px;
    flex: 0 1 auto;
    overflow: auto;
}

.sightings-table {
    border-collapse: collapse;
    width: 100%;
}

.sightings-table th {
    text-align: center;
}

.sightings-table td, .sightings-table th {
    padding: 1px;
}

.sightings-table th {
    padding-top: 0;
    padding-bottom: 2px;
    text-align: center;
    color: #444;
}

.sightings-table .species-count-row:hover {
    background-color: rgba(0, 0, 0, 0.05);
    cursor: pointer;
}

.div-bar-horizontal {
    font-weight: 600;
    font-size: 0.7em;
    float: right;
    border-radius: 10px 2px 2px 10px;
    color: #fff;
    text-align: center;
    padding-right: 5px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.table-species-name {
    font-family: "Alegreya Sans";
    font-weight: 600;
    color: hsl(158, 30%, 35%);
    background: transparent;
    border-radius: 5px;
    padding: 2px 5px;
    /* box-shadow: 0 1px 0px 0 rgba(0, 0, 0, 0.3); */
    text-align: left;
    overflow-x: visible;
    white-space: nowrap;
    max-width: 100px;
}

.additional-row-info>td {
    transition: all 0.2s;
    column-span: 2;
}

.additional-row-info-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

.species-count-row td:first-child {
    width: 75px;
    text-align: left;
}

.sightings-go-button {
    margin: 10px;
    height: 30px;
    width: 120px;
    font-size: 0.8em;
    font-weight: 600;
    background-color: transparent;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 10px;
    box-shadow: 0 2px 0px 0.5px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    border: 5px solid transparent;
    transition: all 80ms;
}

.sightings-go-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.sightings-go-button:active {
    box-shadow: 0 0px 0px 0.5px rgba(0, 0, 0, 0.3) !important;
    transform: translate(0, 2px);
}

.nbn-go-button {
    background-image: url(/static/media/nbn_atlas_text_logo.445ff381.webp);
}

.inat-go-button {
    background-image: url(/static/media/inaturalist_text_logo.cb191646.svg);
}

.explorer-input {
    font-size: 0.8em;
    color: #aaa;
    border-radius: 10px;
    background: #fafafa;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 5px;
    padding: 5px;
    box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.3);
    transition: all 0.2s;
}

.remove-inat-markers {
    border: none;
    border-radius: 0px;
    cursor: pointer;
    border-color: #fff;
    border-style: solid;
    border-width: 3px;
    height: 19px;
    text-align: center;
    font-family: 'Rosario', sans-serif;
    font-weight: bold;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.35);
    position: relative;
    font-size: 16px;
    display: flex;
    background: rgba(255, 120, 150);
    justify-content: center;
    align-items: center;
    color: #fff;
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    overflow: visible;
    white-space: nowrap;
    padding: 10px;
    margin: 5px;
}

.sightings-header-buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-bottom: 5px;
}

.sightings-header-buttons a {
    margin: auto;
    height: 26px;
    display: inline-block;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #333;
    font-weight: 600;
}

.sightings-header-buttons > * {
    margin: auto;
}

.sightings-header-buttons > a > p {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px 5px;
}

.button-mini-image {
    height: 26px;
    width: 26px;
}
.place-search-input {
    width: calc(100% - 10px);
    height: 40px;
    padding: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.place-search-input>input[type=text] {
    width: 60%;
    height: 20px;
    padding: 5px;
    margin: 10px;
    background: transparent;
    box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.3);
}

.go-button {
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    cursor: pointer;
    text-align: center;
}

.x-button::after, .x-button::before {
    background-color: hsla(0,0%,10%,.5);
    border-radius: 3px;
    content: "";
    inset: 0;
    margin: auto;
    position: absolute;
}
.remove-isochrone {
    transform: rotate(45deg);
}
.x-button::before {
    height: 15px;
    width: 4px;
}

.x-button::after {
    height: 4px;
    width: 15px;
}

.remove-isochrone {
    font-size: 10px;
}

.remove-isochrone {
    background: rgb(198, 68, 68);
    color: #fff;
}

.location-boxes-container {
    position: relative;
    width: 100%;
    max-height: calc(100% - 60px);
    overflow-y: scroll;
    padding-top: 10px;
}

.location-box {
    /* background: #abc; */
    width: calc(100% - 20px);
    padding: 5px;
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    cursor: pointer;
}

.location-box:hover {
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
}

.location-name {
    font-weight: 600;

}

.mitm-search {
    padding-top: 10px;
    display: flex;
    max-height: calc(100% - 10px);
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
